.villainsGameHidden {
  display:none;
}

.villainsGameShow {
  display:inline-block;
  background-color: ivory;
  border: 2px solid black;
  padding: 10px;
  width: 90%;
}

.waitingTurnHolder {
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 10px auto auto auto;
  border: 2px solid green;
  padding: 10px;
  width: 75%;
  height: 100px;
  background-color: lightcoral;
  color:darkgreen;
  font-size: 22px;
  font-weight: bold;
}

.hideMockDataButton {
  display:none;
}

.mockDataButton {
  border-radius: 5px;
  background-color: coral;
  padding: 3px;
  font-weight: bold;
  width: fit-content;
  text-align: center;
  margin:auto;
}

.mockDataButton:hover {
  cursor: pointer;
  background-color: lime;
}

.gameCardHolder {
  width: 100%;
}

.welcomeHeader {
  border-radius: 10px;
  color: lightgreen;
  background-color: maroon;
  font-weight: bold;
  font-size: 20px;
}


.characterIcon {
  border: 1px solid black;
  width: fit-content;
  text-align:center;
  margin:auto;
  margin-top: 20px;
}
