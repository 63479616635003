.gameCard {
  margin-top: 20px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: ivory;
  width: 80%%;
  height: 400px;
  margin-left:10px;
  margin-right: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.basicButton {
  margin-top: 10px;
  color:white;
  border-radius: 5px;
  padding: 7px;
  margin-right: 10px;
}

.completeTurnButton {
  margin-top: 10px;
  background-color: crimson;
  color:white;
  border-radius: 5px;
  padding: 7px;
}

.completeTurnButton:hover {
  cursor: pointer;
  background-color:cadetblue;
}

.performMagicButton {
  background-color: fuchsia;
}

.performMagicButton:hover {
  background-color: chartreuse;
  color:black;
  padding: 10px;
}

.castSpellButton {
  background-color: darkslategrey;
}

.castSpellButton:hover {
  background-color: dimgrey;
  color:khaki;
  padding: 10px;
}

.spellCardHeader {
  background-color:indianred;
  color:lavenderblush;
  border-top-left-radius:10px;
  border-top-right-radius: 10px;

}

.spellBackfire {
  background-color: black;
  color:cyan;
  padding: 3px;
  margin-bottom: 5px;
}

.optionsChosenText {
  border: 1px solid black;
  padding: 10px;
  background-color: limegreen;
}
