.adminHeader {
  width: 100%;
  text-align: center;
  background-color: coral;
  color: black;
  height: 30px;
  margin-bottom: 10px;
  font-weight:bold;
}

.dataDiv {
  border: 1px solid black;
  width: fit-content;
  margin: auto;
  margin-top: 20px;
}

.adminButton {
  margin: 10px;
  cursor: pointer;
}

.adminButton:hover {
  background-color: aliceblue;
}

.adminStartButton {
  border-radius: 5px;
  margin: 10px;
  padding: 2px;
  background-color: aquamarine;
}

.adminResetButton {
  border-radius: 5px;
  margin: 10px;
  padding: 2px;
  background-color: cornsilk;
}

.adminTitle {
  float: right;
  margin-right: 20px;
}

.adminHeaderButton {
  float: left;
  border-radius: 5px;
  background-color: #9FE2BF;
  padding: 2px;
  margin: 2px;
  font-weight: bold;
}

.adminHeaderButton:hover {
  cursor: pointer;
  background-color: cornflowerblue;
}
