.gameHeader {
  background-color:blue;
  width:100%;
  height: 30px;
  margin-bottom: 10px;
  padding: 3px;
  text-align: left;
}

.chooseGameHeader {
  background-color: red;
  border: 1px solid green;
  font-weight:bold;
  text-align: center;
  padding: 5px;
  width: fit-content;
  margin: auto;
}

.joinGame {
  display:inline;
}

.joinGameClassHidden {
  display:none;
}

.chooseUserClassHidden {
  display:none;
}

.chooseUserClass {
  display:inline;
}

.chooseButtonHide {
  display:none;
}

.chooseButtonShow {
  display:inline;
  border-radius: 5px;
  background-color: coral;
  padding: 2px;
  font-weight: bold;
}

.chooseButtonShow:hover {
  cursor: pointer;
  background-color: cornflowerblue;
}

.currentUser {
  float: right;
  margin-right: 20px;
}

.hideStartButton {
  display:none;
}

.showStartButton {
  display:inline-block;
  margin-left: 5px;
  border-radius: 5px;
  background-color: olive;
  padding: 2px;
  font-weight: bold;
}

.showStartButton:hover {
  cursor: pointer;
  background-color: cornflowerblue;
}
